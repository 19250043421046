// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { API_URL } from "../constants/config";

// const ViewReport = () => {
//   const { id } = useParams();
//   const [reportHtml, setReportHtml] = useState("");

//   useEffect(() => {
//     let token = localStorage.getItem("token");
//     axios
//       .get(`${API_URL}/get-report-data/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then(function (response) {
//         console.log(response);
//         setReportHtml(response.data.data.report_html);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }, [id]);

//   return (
//     <>
//       {reportHtml === "" ? (
//         "Loading..."
//       ) : (
//         <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
//       )}
//     </>
//   );
// };

// export default ViewReport;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../components/Logo";
import { API_URL } from "../constants/config";

const ViewReport = () => {
  const { id } = useParams();
  const [reportHtml, setReportHtml] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${API_URL}/get-report-data/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setReportHtml(response.data.data.report_html);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  return (
    <>
      {reportHtml === "" ? (
        <div className="relative z-10">
          <div className="preloader-main w-full h-screen bg-white absolute opacity-90">
            <div className="preloader-div flex items-center justify-center flex-col h-full">
              <Logo animate={true} />
            </div>
          </div>
        </div>
      ) : (
        <iframe
          title="Report"
          style={{
            display: "block",
            width: "100%",
            height: "100vh",
            border: "none",
          }}
          srcDoc={reportHtml}
        />
      )}
    </>
  );
};

export default ViewReport;
