import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../constants/config";

function SortableTable({
  data,
  fields,
  rowsPerPageOptions = [3, 5, 10, 25],
  defaultRowsPerPage = 5,
}) {
  // Set default sort to "created_at" in descending order
  const [orderBy, setOrderBy] = useState(fields[0].created_at); // Default sorting column
  const [order, setOrder] = useState("desc"); // Default sorting order

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  // Function to handle sorting
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAscending ? "desc" : "asc");
  };

  // Function to compare values for sorting
  const compareValues = (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Check if the field is "created_at" and handle it as a date
    if (orderBy === "created_at") {
      const dateA = new Date(aValue);
      const dateB = new Date(bValue);

      // Compare the dates
      if (dateA < dateB) return order === "asc" ? -1 : 1;
      if (dateA > dateB) return order === "asc" ? 1 : -1;
      return 0;
    }

    // Default comparison for other fields
    if (aValue < bValue) return order === "asc" ? -1 : 1;
    if (aValue > bValue) return order === "asc" ? 1 : -1;
    return 0;
  };

  // Sort the rows based on current sorting criteria
  const sortedRows = data.slice().sort(compareValues);

  // Pagination logic
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePDF = (ur_id) => {
    console.log("generatePDF", ur_id);
    let token = localStorage.getItem("token");

    axios
      .get(`${API_URL}/generate-pdf/${ur_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data?.data?.pdf_url);
        if (response.data?.data?.pdf_url)
          window.open(response.data?.data?.pdf_url, "_blank");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatCell = (row, value, column) => {
    if (column === "view")
      return (
        <a
          className="text-cf--primary underline text-xs"
          href={`/view-report/${row["ur_id"]}`}
          target="_blank"
          rel="noreferrer"
        >
          View report
        </a>
      );

    // if (column === "pdf")
    //   return (
    //     <button
    //       className="px-2 py-1 bg-cf--primary rounded hover:bg-cf--secondary transition-all text-xs text-white"
    //       onClick={() => generatePDF(row["ur_id"])}
    //     >
    //       To PDF
    //     </button>
    //   );

    if (column === "price") return 1;

    if (column === "created_at") {
      const date = new Date(value);
      const formattedDate = `
        ${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} 
        ${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}
      `;
      return formattedDate;
    }

    return value;
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className="soratble-table text-sm">
          <TableHead className="bg-cf--off-white">
            <TableRow>
              {fields.map((field) => (
                <TableCell key={field.id}>
                  <TableSortLabel
                    active={orderBy === field.id}
                    direction={orderBy === field.id ? order : "asc"}
                    onClick={() => handleSort(field.id)}
                  >
                    {field.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {fields.map((field) => (
                    <TableCell key={field.id}>
                      {formatCell(row, row[field.id], field.id)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div className="w-full absolute left-[-9999px]" id="tbl-pdf"></div>
    </div>
  );
}

export default SortableTable;
