import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AutoCheckExample from "./routes/AutoCheckExample";
import CancelPage from "./routes/Cancel";
import CarfaxExample from "./routes/CarfaxExample";
import Dashboard from "./routes/Dashboard";
import EmailActivation from "./routes/EmailActivation";
import ForgotPassword from "./routes/ForgotPassword";
import History from "./routes/History";
import Payments from "./routes/Payments";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Signin from "./routes/Signin";
import Signup from "./routes/Signup";
import SuccessPage from "./routes/Success";
import Support from "./routes/Support";
import TermsOfService from "./routes/TermsOfService";
import ViewReport from "./routes/ViewReport";
import history from "./utils/history";
import ProtectedRoute from "./utils/ProtectedRoute";
import ViewPDFReport from "./routes/viewPdfReport";

function App() {
  return (
    <>
      <Router history={history}>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/autocheck-example" element={<AutoCheckExample />} />
          <Route path="/carfax-example" element={<CarfaxExample />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/api-description" element={<APIDescription />} /> */}
          <Route path="/support" element={<Support />} />

          <Route
            path="/dashboard"
            element={<ProtectedRoute element={Dashboard} />}
          />
          <Route
            path="/history"
            element={<ProtectedRoute element={History} />}
          />
          <Route
            path="/payments"
            element={<ProtectedRoute element={Payments} />}
          />
          <Route
            path="/view-report/:id"
            element={<ProtectedRoute element={ViewReport} />}
          />
          <Route
            path="/view-pdf-report/:id"
            element={<ProtectedRoute element={ViewPDFReport} />}
          />
          <Route
            path="/activate-account/:id"
            element={<ProtectedRoute element={EmailActivation} />}
          />
          <Route
            path="/payment/success"
            element={<ProtectedRoute element={SuccessPage} />}
          />
          <Route
            path="/payment/cancel"
            element={<ProtectedRoute element={CancelPage} />}
          />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
